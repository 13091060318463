.ac-takanon-page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 11vh;
    overflow: hidden;
    box-sizing: border-box;
    overflow-y: scroll;
}

.ac-takanon-page .ac-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.ac-takanon-page .ac-img-wrapper {
    width: 90%;
}

.ac-takanon-page .ac-takanon-text {
    font-size: 7.5vw;
    margin: 4vh auto 2vh;
    font-family: "FbSpoilerRegular";
    font-family: "FbReformaNarrow-Regular";
    text-align: center;
}

.ac-takanon-page .ac-btn.ac-start-game-btn {
    margin: 2vh auto;
}

.ac-takanon-page .ac-btn.ac-start-game-btn .ac-inner-btn {
    padding-bottom: 1vw;
}

.ac-takanon-page .ac-img-wrapper-2 {
    margin-top: 9vh;
}

.ac-takanon-page .ac-takaon-link {
    background-color: #004FA3;
    color: #fff;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    margin: 0 auto;
    align-items: center;
    text-decoration: none;
    height: 10%;
    padding: 2% 6%;
    width: 73%;
    font-size: 200%;
    font-family: "FbReformaNarrowMedium";
    border: 2px solid #fff;
}
.ac-overlay{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgb(0 78 161 / 65%) 1%, rgb(0 0 0 / 65%) 100%);
}