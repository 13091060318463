.ac-upload-form form{
    position: relative;
    width: 90%;
    margin: 19vw auto 0;
}
.ac-upload-form form .ac-text-bg-box{
    position: absolute;
}
.ac-upload-form form .ac-text-box,
.ac-upload-form form .ac-input,
.ac-upload-form form .send{
    position: relative;
}
.ac-upload-form form input {
    display: block;
    border-radius: 38px;
    background: #fff;
    border: 1px solid #fff;
    color: #004FA3;
    font-size: 5vw;
    padding: 1vh 3vh;
    margin-bottom: 4vw;
    width: 100%;
    height: 13vw;
    box-sizing: border-box;
}
.ac-upload-form form .ac-hide {
    display: none;
}
.ac-upload-form form input::-webkit-input-placeholder,
.ac-upload-form form input::-webkit-input-placeholder {
    color: #004FA3;
    font-size: 6vw;
    font-family: "FbReformaNarrow-Regular";
}

.ac-upload-form form input:-ms-input-placeholder,
.ac-upload-form form input:-ms-input-placeholder {
    color: #004FA3;
    font-size: 6vw;
    font-family: "FbReformaNarrow-Regular";
}

.ac-upload-form form input::placeholder,
.ac-upload-form form input::placeholder {
    color: #004FA3;
    font-size: 6vw;
    font-family: "FbReformaNarrow-Regular";
}
.ac-upload-form form label[for="upload-button"] {
    background-color: #004FA3;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    width: 100%;
    height: 13vw;
    border-radius: 30px;
    box-sizing: border-box;
    margin: 1vw auto;
    font-size: 130%;
}
.ac-upload-form form label[for="upload-button"] .ac-icon-upload-image {
    width: 8vw;
    height: 7vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
}
.ac-upload-form form label[for="upload-button"] .ac-icon-upload-image svg {
    width: 100%;
    height: 100%;
}
.ac-upload-form form .send {
    background-color: #004FA3;
    width: 85%;
    height: 15vw;
    margin: 4vw auto;
    border: 3px solid #fff;
    border-radius: 38px;
    font-family: "FbReformaNarrowMedium";
    font-size: 140%;
}
.ac-upload-form form .send {
    font-family: "FbReformaNarrowMedium";
    font-size: 140%;
}
.ac-upload-form form form button span.ac-arrows {
    position: relative;
    top: 0.4vw;
    margin-right: 4%;
    width: 5vw;
    height: 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.ac-upload-form svg {
    width: 100%;
    height: 100%;
}
.ac-upload-form .ac-image6546{
    position: absolute;
    top: 0;
}
.ac-upload-form .ac-overlay {
    position: absolute;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.88) 0%, rgba(0, 1, 2, 0.01) 100%);
    width: 100vw;
    height: 100vh;
}
.ac-upload-form{
    position: absolute;
    top: 0;
    width: 100%; 
}
.ac-upload-form .ac-logo{
    position: relative;
    top: 8vw;
}
.ac-upload-form form .ac-text-box .ac-text{
    font-size: 180%;
    position: relative;
    top: 0.8vw;
}
.ac-upload-form form .ac-text-box {
    margin-bottom: 15vw;
}
.ac-upload-form form .ac-input,
.ac-upload-form form .send{
    width: 85%;
    margin: auto;
}
.ac-upload-form form .ac-input.ac-input-upload{
    margin: 8vw auto 13vw;
}
.ac-upload-form form .ac-arrows{
    margin-right: 10px;
    position: relative;
    top: 0.25vw;
}

.ac-upload-form .ac_help_center {
    position: relative;
    background: linear-gradient(to bottom, rgba(0, 78, 161, 1) 0%, rgba(0, 1, 2, 1) 100%);
    padding: 9% 5% 5% 5%;
    width: 80%;
    border-radius: 30px;
    border: 1px solid #fff;
    left: unset;
    top: unset;
    transform: unset;
    margin: 21vw auto 0;
}
.ac-upload-form .ac-thanks .ac-btn {
    background-color: #004FA3;
    text-wrap: nowrap;
    font-size: 113%;
    width: 66%;
}
.ac-upload-form .ac-thanks .ac-btn span{
    font-family: "FbReformaNarrow-Regular";
    font-weight: 300;
}