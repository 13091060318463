.ac-goals-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 5vh;
    overflow: scroll;
    box-sizing: border-box;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#004ea1+1,000000+100&0.87+0,0.73+100 */
background: linear-gradient(to bottom,  rgba(0,78,161,0.87) 0%,rgba(0,78,161,0.87) 1%,rgba(0,0,0,0.73) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}

.ac-goals-page .ac-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.ac-goals-page .ac-goalspage-textplace {
    font-size: 6vw;
    font-family: "FbSpoilerRegular";
    font-family: "FbReformaNarrow-Regular";
}

.ac-goals-page .ac-goalspage-text {
    font-size: 11vw;
    font-family: "FbSpoilerBold";
    font-family: "FbReformaNarrowBold";
    display: block;
    margin-top: 0vw;
    line-height: 0.7;
    text-align: center;
    margin-bottom: 2.5vh;
}

.ac-goals-page .ac-img-wrapper {
    width: 100%;
}
.ac-goals-page .ac-img-wrapper .ac-logo{
    width: 85vw;
}
.ac-goals-page .ac-btn.ac-start-game-btn {
    margin: 2vh auto;
}

.ac-goals-page .ac-btn.ac-start-game-btn .ac-inner-btn {
    padding-bottom: 2vw;
}
.ac-goals-page .ac-title{
    display: block;
    font-size: 12vw;
    font-family: "FbReformaNarrowMedium";
    margin-bottom: 6vw;
    margin-top: 10vw;
}
.ac-goals-page .ac-text{
    font-size: 9vw;
    margin-bottom: 10vw;
    text-align: center;
}
.ac-goals-page .ac-close-wrapper{
    position: relative;
    width: 100%;
}
.ac-goals-page .ac-close-wrapper a {
    text-decoration: none;
    color: #fff;
    font-size: 27vw;
    position: absolute;
    right: 0;
    top: -41px;
    transform: rotate(45deg);
}
.ac-goals-page .ac-overlay{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgb(0 78 161 / 65%) 1%, rgb(0 0 0 / 65%) 100%);
}