.ac-price-page {
    width: 100%;
    height: 90vh;
}

.ac-price-page .ac-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    /* -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; */
    height: 85vh;
}

.ac-price-page p {
    text-align: center;
    font-size: 3.1vh;
}

.ac-price-page p small {
    line-height: 1;
    font-size: 2.1vh;
    font-size: 32px;
    font-size: 4vh;
    color: #004B9D;
    font-family: 'FbSpoilerBold';
}

.ac-price-page p .ac-bigger {
    font-family: 'PlutoBold';
    color: #fff;
    /* font-size: 38px; */
    font-size: 6vh;
    font-weight: 900
}

.ac-price-page p .ac-medium {
    /* font-size: 31px; */
    font-size: 5vh;
    font-family: 'PlutoBold';
}

.ac-price-page .ac-img-wrapper {
    width: auto;
    margin: 0 auto;
    height: 29vh;
}

.ac-price-page .ac-container .bread {
    margin-bottom: 5vh;
    position: absolute;
    top: 50px;
}

.ac-price-page .ac-container .bread a {
    font-family: 'FbSpoilerRegular';
    font-size: 5vw;
    text-decoration: none;
    color: #fff;
}

.ac-price-page .ac-img-wrapper img {
    width: auto;
    height: 100%;
}