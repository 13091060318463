.ac-openning-page {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}

.ac-openning-page .ac-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 100vh; */
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow-y: scroll;
}
.ac-openning-page .ac-text-box{
    width: 85%;
    margin: 0 auto;
    position: relative;
    height: 110vw;
    text-align: center;
}
.ac-openning-page .ac-text-bg-box{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.ac-openning-page .ac-text-box .ac-text{
    font-size: 140%;
    font-family: "FbReformaNarrowMedium";
    display: block;
    line-height: 0.9;
}
.ac-openning-page .ac-text-box .ac-text.ac-text-20{
    font-size: 94%;
    line-height: 1;
}
.ac-openning-page .ac-text-box .ac-text.ac-text-33{
    font-size: 154%;
}
.ac-openning-page .ac-text-box .ac-text-m-1{
    font-size: 176%;
    margin: 1vw auto 4vw;
}
.ac-openning-page .ac-text-box .ac-text-b {
    font-family: "FbReformaNarrowBold";
}
.ac-openning-page .ac-text-box-2{
    width: 100%;
    margin: 0 auto;
    position: relative;
    height: auto;
    text-align: center;
    background: linear-gradient(to bottom, rgba(169, 156, 201, 1) 0%, rgba(64, 54, 89, 1) 100%);
    padding: 1% 0 2%;
    border-top: 1px solid #fff;
}
.ac-openning-page p {
    text-align: center;
    /* font-size: 3.4vh; */
    font-size: 109%;
    font-family: "FbSpoilerRegular";
    font-family: "FbReformaNarrow-Regular";
    letter-spacing: 0.1vw;
}

.ac-openning-page p small {
    line-height: 1;
    /* font-size: 2.1vh; */
    font-size: 80%;
}

.ac-openning-page p:nth-child(3) {
    margin-top: 3%;
    margin-bottom: 5%;
}


/* .ac-openning-page .ac-start-game-btn {
  
    background-color: #fff;
    color: #004fa3;
    border-radius: 42px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    margin: 0 auto;
    align-items: center;
    text-decoration: none;
    width: max-content;
    height: 7vh;
    padding: 0 6%;
} */


/* .ac-openning-page .ac-start-game-btn .ac-inner-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 4.5vh;
} 

.ac-openning-page .ac-start-game-btn .ac-inner-btn span {
    position: relative;
}

.ac-openning-page .ac-start-game-btn .ac-inner-btn svg {
    fill: #004fa3;
    width: 3vw;
    margin-right: 10px;
} */

.ac-openning-page .ac-img-wrapper {
    margin: 0 auto;
    width: auto;
    height: auto;
    margin-bottom: 1%;
    text-align: center;
    margin-top: 9%;
}

.ac-openning-page .ac-img-wrapper img {
    width: 100%;
    height: auto;
    margin-top: 10%;
}
.ac-openning-page .ac-img-wrapper img.ac-herkev {
    width: 57%;
    margin-top: 0;
}
.ac-openning-page .ac-img-wrapper.ac-car {
    margin-top: 0%;
}

.ac-openning-page .ac-img-wrapper.ac-car img {
    width: 57%;
    margin-top: 0;
}
.ac-openning-page .container-3{
    height: 100vh;
    display: flex;
}
.ac-openning-page .container-3 .ac-image6546{
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}
.ac-openning-page .container-3 .ac-overlay{
    position: absolute;
 /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000102+100&0.88+0,0.01+100 */
 background: linear-gradient(to bottom,  rgba(0,0,0,0.88) 0%,rgba(0,1,2,0.01) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
 width: 100vw;
 height: 100vh;
}
.ac-openning-page .container-3 .ac-popup-start-game{
    background: linear-gradient(to bottom, rgba(0, 77, 160, 1) 0%, rgba(0, 1, 2, 1) 100%);
    width: 90%;
    margin: auto;
    border: 1px solid #fff;
    border-radius: 33px;
    padding: 10% 5% 5% 5%;
    box-sizing: border-box;
    height: auto;
    z-index: 1;
}
.ac-openning-page .ac-popup-start-game .ac-text-box{
    height: auto;
}
.ac-image360 {
    width: 25vw;
    margin: auto;
    display: block;
}
.ac-Group52{
    width: 80%;
    margin: 1vw auto;
}

.ac-openning-page .container-3 .ac-img-wrapper img{
    margin-top: -10%;
    margin-bottom: 10vw;
}