@media (min-width: 600px) {
    .ac-loader-wrapper.ac-desktop-page {
        height: 100vh;
        flex-direction: column;
        /* overflow: hidden; */
    }
    @font-face {
        font-display: swap;
        font-family: "FbReformaNarrow-Regular";
        src: url(../fonts/FbReformaNarrow-Regular.eot);
        src: url(../fonts/FbReformaNarrow-Regular.eot) format("embedded-opentype"),
           url(../fonts/FbReformaNarrow-Regular.woff) format("woff"),
           url(../fonts/FbReformaNarrow-Regular.ttf) format("truetype"),
           url(../fonts/FbReformaNarrow-Regular.svg) format("svg");
        /*font-weight:normal;font-style:normal;*/
    }
    @font-face {
        font-display: swap;
        font-family: "FbReformaNarrowMedium";
        src: url(../fonts/FbReformaNarrowMedium.eot);
        src: url(../fonts/FbReformaNarrowMedium.eot) format("embedded-opentype"),
           url(../fonts/FbReformaNarrowMedium.woff) format("woff"),
           url(../fonts/FbReformaNarrowMedium.ttf) format("truetype"),
           url(../fonts/FbReformaNarrowMedium.svg) format("svg");
        /*font-weight:normal;font-style:normal;*/
    }
    @font-face {
        font-display: swap;
        font-family: "FbReformaNarrowBold";
        src: url(../fonts/FbReformaNarrowBold.eot);
        src: url(../fonts/FbReformaNarrowBold.eot) format("embedded-opentype"),
           url(../fonts/FbReformaNarrowBold.woff) format("woff"),
           url(../fonts/FbReformaNarrowBold.ttf) format("truetype"),
           url(../fonts/FbReformaNarrowBold.svg) format("svg");
        /*font-weight:normal;font-style:normal;*/
    }
    .ac-desktop-page .ac-bg{
        position: absolute;
        top:0;
        left:0;
        /* width: 100%;
        height: 100%;
        object-fit: cover; */
    }
    .ac-loader-wrapper.ac-desktop-page img{
        width:100%;
        height: auto;
        margin: 0 auto;
        display: block

    }
    .ac-desktop-page .ac-bg,
    .ac-loader-wrapper.ac-desktop-page .ac-bg{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .ac-loader-wrapper.ac-desktop-page img.ac-logo {
        position: absolute;
        width: 30%;
        top: 15%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .ac-desktop-page .ac-content{
        position: relative;
        width: 42%;
        /* margin: 14% auto 0; */
        margin: 25vh auto 0;
    }
    .ac-desktop-page .ac-wrapperBg{
        position: absolute;
        top: 0;
    }
    .ac-desktop-page .ac-title{
        font-family: "FbReformaNarrowMedium";
        position: relative;
        font-size: 50%;
        top: 0.7vw;
    }
    .ac-desktop-page .ac-main-text{
        font-family: "FbReformaNarrowMedium";
        position: relative;
        font-size: 41%;
        text-align: center;
        top: 4vw;
    }
    .ac-desktop-page .ac-star{
        font-family: "FbReformaNarrowMedium";
        position: relative;
        font-size: 23%;
        top: 4.5vw;
    }
    .ac-desktop-page .ac-col-wrapper{
        display: flex;
        position: relative;
        width: 100%;
        margin: auto;
        top: 8vw;
    }
    .ac-desktop-page .ac-img-text{
        font-family: "FbReformaNarrowMedium";
    }
    .ac-desktop-page .ac-col-wrapper .ac-col-1,
    .ac-desktop-page .ac-col-wrapper .ac-col-3 {
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 20%;
        
    }
    .ac-desktop-page .ac-col-wrapper .ac-col-1{
        width: 52%;
    }
    .ac-desktop-page .ac-col-wrapper .ac-col-3{
        width: 41%;
    }
    .ac-desktop-page .ac-col-wrapper .ac-col-2 {
        width: 3%;
    }
    .ac-desktop-page .ac-col-wrapper .ac-col-2 span{
        width: 1px;
        height: 100%;
        background: #fff;
    }
    .ac-desktop-page .ac-col-wrapper .ac-qr {
        width: 68%;
        margin-bottom: 0.8vw;
    }
    .ac-desktop-page .ac-col-wrapper .ac-Group54{
        width: 67%;
        margin-bottom: 0.8vw;
    }
}