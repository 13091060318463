.ac-faq-page {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    padding-top: 0vw;
    box-sizing: border-box;
    width: 100%;
}

.ac-faq-page .ac-container {
    flex-direction: column;
    width: 90%;
    overflow-y: scroll;
    height: 100vh;
    position: relative;
}

.ac-faq-page .ac-faq-title {
    width: 100%;
    text-align: right;
    font-size: 10.5vw;
    font-family: "FbReformaNarrowMedium";
    margin-bottom: 3vw;
    margin-top: 11vw;
}

.ac-faq-page .accordion__button {
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    text-align: right;
    border: none;
    width: 100%;
    padding: 1vh 5vw 1vh 0vw;
    border-top: 0.1vw solid #ffffff70;
    box-sizing: border-box;
    font-size: 7vw;
    font-family: "FbSpoilerBold";
    font-family: "FbReformaNarrowBold";
    display: flex;
    justify-content: space-between;
    height: 18vw;
}

.ac-faq-page .accordion__button .ac-question {
    width: 80%;
    display: flex;
    align-items: center;
}

.ac-faq-page .accordion__button .ac-arrow {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ac-faq-page .accordion__button .ac-arrow span {
    transform: rotate(45deg);
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    content: "";
    height: 10px;
    width: 10px;
}

.accordion__button[aria-expanded="true"] .ac-arrow span,
.accordion__button[aria-selected="true"] .ac-arrow span {
    transform: rotate(224deg);
}

.ac-faq-page .accordion {
    border: 0px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    width: 100%;
    margin: 0 auto;
}

.ac-faq-page .ac-btn.ac-start-game-btn {
    margin: 4vh auto;
}


/* .ac-faq-page .accordion__button:before {
    margin-left: 6vw;
    position: absolute;
    left: 0;
    transform: rotate(45deg);
    border-bottom: 3px solid currentColor;
    border-right: 3px solid currentColor;
} */

.ac-faq-page .accordion__button:before {
    display: none;
}


/* .accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
    transform: rotate(-136deg);
} */

.ac-faq-page .accordion__panel {
    padding: 0 5vw 5vw;
    animation: fadein 0.35s ease-in;
    font-size: 6vw;
    font-family: "FbSpoilerRegular";
    font-family: "FbReformaNarrow-Regular";
    margin-top: -1vw;
}

.ac-faq-page .ac-btn.ac-start-game-btn .ac-inner-btn {
    padding-bottom: 1vw;
}

.ac-faq-page .ac-title{
    display: block;
    font-size: 12vw;
    font-family: "FbReformaNarrowMedium";
    margin-bottom: 6vw;
    margin-top: 10vw;
}
.ac-faq-page .ac-text{
    font-size: 9vw;
    margin-bottom: 10vw;
    text-align: center;
}
.ac-faq-page .ac-close-wrapper{
    position: relative;
    width: 100%;
}
.ac-faq-page .ac-close-wrapper a {
    text-decoration: none;
    color: #fff;
    font-size: 27vw;
    position: absolute;
    right: 0;
    top: -55px;
}
.ac-faq-page .ac-overlay{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgb(0 78 161 / 65%) 1%, rgb(0 0 0 / 65%) 100%);
}
.ac-faq-page .ac-logo{
    width: 70vw;
    margin: 8vw auto 0;
    display: block;
    position: relative;
}
.ac-q {
    text-align: right;
    font-family: "FbReformaNarrowMedium";
    font-size: 6.3vw;
    display: block;
    background-color: #7D69AD;
    padding: 0.1% 2%;
    width: max-content;
    margin-bottom: 2vw;
}
.ac-a {
    text-align: right;
    font-family: "FbReformaNarrowMedium";
    font-size: 6.2vw;
    margin-bottom: 6vw;
}