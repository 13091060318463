
.ac-wrapper-popup{
    background: linear-gradient(to bottom, rgba(0, 77, 160, 1) 0%, rgba(0, 1, 2, 1) 100%);
    width: 90%;
    margin: auto;
    border: 1px solid #fff;
    border-radius: 33px;
    padding: 12% 5% 5% 5%;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.ac-wrapper-popup .ac-text-box{
    width: 85%;
    margin: 0 auto;
    position: relative;
    height: auto;
    text-align: center;
}
.ac-wrapper-popup .ac-text-box .ac-text{
    font-size: 140%;
    font-family: "FbReformaNarrow-Regular";
    display: block;
    line-height: 1;
}
.ac-wrapper-popup .ac-text-box .ac-text-m{
    font-family: "FbReformaNarrowMedium";
}
.ac-wrapper-popup .ac-text-box .ac-text-b {
    font-family: "FbReformaNarrowBold";
    margin-bottom: 12vw;
}
.ac-wrapper-popup.ac-open {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.ac-wrapper-popup p {
    text-align: center;
    font-family: "FbSpoilerRegular";
    font-family: "FbReformaNarrow-Regular";
    font-size: 123%;
    margin: 2vh auto;
    color: #fff;
}
.ac-your-time{
    font-family: "Digital-7";
    font-size: 500%;
    margin-top: -7vw;
    margin-bottom: 7vw;
}
.ac-wrapper-popup .ac-inner {
    width: 80%;
    height: auto;
    padding: 8%;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 40px;
    box-sizing: border-box;
    background: #00b2ef;
    background: radial-gradient(ellipse at center, #00b2ef 0%, #004fa3 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00b2ef', endColorstr='#004fa3', GradientType=1);
    background: linear-gradient(to bottom, rgba(0, 78, 161, 1) 0%, rgba(0, 1, 2, 1) 100%);
}

.ac-wrapper-popup .ac-close {
    position: relative;
    width: 100%;
    height: 0;
    margin-bottom: 4vh;
}

.ac-wrapper-popup .ac-close button.ac-close-btn {
    /* width: 30px;
    height: 30px; */
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: #00b8f1;
}

.ac-wrapper-popup.ac-close {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
}

.ac-wrapper-popup.ac-open .ac-img-wrapper {
    width: 95%;
    margin: 0 auto;
}